export enum ConversationRecipientType {
    /**
     * The recipient type for a conversation is the account
     */
    ACCOUNT = 'ACCOUNT',
    /**
     * The recipient type for a conversation is a named area
     */
    NAMED_AREA = 'NAMED_AREA',
    /**
     * The recipient type for a conversation is a team
     */
    TEAM = 'TEAM',
    /**
     * The recipient type for a conversation is an agent
     */
    AGENT = 'AGENT'
}