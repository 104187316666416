import {ApiBridge} from "../api-bridge"
import {InternalModule} from "./module"
import {NewConversationInterceptor} from "../../new-conversation-interceptor"
import {ConversationType} from "../../model/conversation-type"
import {PersonInfo} from "../../model/person-info"
import {ConversationInfo} from "../../model/conversation-info"
import {NewConversationRecipient} from "../../model/new-conversation-recipient";

export type BaseGeneralEventType =
    'activeConversationChange' |
    'conversationsChange' |
    'notificationCountChange' |
    'personChange' |
    'personActivity'

export enum BaseGeneralFunction {
    startConversation = 'startConversation',
    setNewConversationInterceptor = 'setNewConversationInterceptor',
    joinConversation = 'joinConversation',
    openConversation = 'openConversation',
    getActiveConversation = 'getActiveConversation',
    getConversations = 'getConversations',
    getNotificationCount = 'getNotificationCount',
    getPersonInfo = 'getPersonInfo',
    setPersonNickname = 'setPersonNickname',
    addPersonLabel = 'addPersonLabel',
    removePersonLabel = 'removePersonLabel'
}

export class BaseGeneralModule<T extends string | BaseGeneralFunction, E extends BaseGeneralEventType | string> extends InternalModule<BaseGeneralFunction | T, E> {
    constructor(bridge: ApiBridge) {
        super(bridge, 'general')
    }

    public startConversation(type: ConversationType, visitorName?: string, visitorData?: string, recipient?: NewConversationRecipient): Promise<string> {
        return this.callApiFunction(BaseGeneralFunction.startConversation, [type, visitorName, visitorData, recipient])
    }

    public setNewConversationInterceptor(callback: NewConversationInterceptor): Promise<void> {
        return this.callApiFunction(BaseGeneralFunction.setNewConversationInterceptor, [callback])
    }

    public joinConversation(pin: string, visitorName?: string): Promise<string> {
        return this.callApiFunction(BaseGeneralFunction.joinConversation, [pin, visitorName])
    }

    public openConversation(conversationId: string): Promise<void> {
        return this.callApiFunction(BaseGeneralFunction.openConversation, [conversationId])
    }

    public getActiveConversation(): Promise<string | null> {
        return this.callApiFunction(BaseGeneralFunction.getActiveConversation, [])
    }

    public getConversations(): Promise<ConversationInfo[]> {
        return this.callApiFunction(BaseGeneralFunction.getConversations, [])
    }

    public getNotificationCount(): Promise<number> {
        return this.callApiFunction(BaseGeneralFunction.getNotificationCount, [])
    }

    public getPersonInfo(): Promise<PersonInfo> {
        return this.callApiFunction(BaseGeneralFunction.getPersonInfo, [])
    }

    public setPersonNickname(nickname: string): Promise<void> {
        return this.callApiFunction(BaseGeneralFunction.setPersonNickname, [nickname])
    }

    public addPersonLabel(labelName: string): Promise<void> {
        return this.callApiFunction(BaseGeneralFunction.addPersonLabel, [labelName])
    }

    public removePersonLabel(labelName: string): Promise<void> {
        return this.callApiFunction(BaseGeneralFunction.removePersonLabel, [labelName])
    }
}