import {UnbluApiError, UnbluErrorType} from "./shared/unblu-api-error"
import {InternalEmbeddedApi} from "./internal/internal-embedded-api"
import {IntegrationType, UnbluUtil} from "./shared/internal/unblu-util"
import {ApiBridge} from "./shared/internal/api-bridge"
import {ViewMode} from "./view-mode"
import {ApiState} from "./shared/api-state"
import {InitializedUnbluElement} from "./shared/internal/initialized-unblu-element"
import {UnbluEmbeddedApi} from "./unblu-embedded-api"
import {Conversation} from "./shared/conversation"
import {Configuration} from "./shared/model/configuration";

/**
 * Event emitted whenever the API state changes
 *
 * @see {@link UnbluEmbeddedAppElement.STATE_CHANGE}
 * @see {@link UnbluEmbeddedAppElement.addEventListener}
 * @see {@link UnbluEmbeddedAppElement.removeEventListener}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent | Custom Event}
 * @noInheritDoc
 */
export declare interface StateChangeEvent extends CustomEvent<ApiState> {
    /**
     * The new api state
     */
    readonly detail: ApiState
}

/**
 * Event emitted as soon as the API is initialized.
 * It emits an instance of {@link UnbluEmbeddedApi} which can be used for further API calls.
 *
 * This event is useful when {@link UnbluEmbeddedAppElement.autoInit} is true to always get the latest API instance.
 *
 * **Note**: that this event will be triggered again after each initialization.
 *
 * @see {@link UnbluEmbeddedAppElement.READY}
 * @see {@link UnbluEmbeddedAppElement.addEventListener}
 * @see {@link UnbluEmbeddedAppElement.removeEventListener}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent | Custom Event}
 * @noInheritDoc
 */
export declare interface ReadyEvent extends CustomEvent<UnbluEmbeddedApi> {
}

/**
 * Event emitted if the API initialization fails.
 *
 * It usually makes sense to use this event if there is some general action that has to be triggered when the API initialization fails.
 *
 * @see {@link UnbluEmbeddedAppElement.ERROR}
 * @see {@link UnbluEmbeddedAppElement.addEventListener}
 * @see {@link UnbluEmbeddedAppElement.removeEventListener}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent | Custom Event}
 * @noInheritDoc
 */
export declare interface ErrorEvent extends CustomEvent<UnbluApiError> {
    /**
     * The error which occurred
     */
    readonly detail: UnbluApiError
}

/**
 * Event emitted when the de-initialization of the API is started.
 *
 * It usually makes sense to use this event to clean up resources and/or unregistering of listeners so they don't try to use the API again until it is initialized again.
 *
 * @see {@link UnbluEmbeddedAppElement.DEINITIALIZING}
 * @see {@link UnbluEmbeddedAppElement.addEventListener}
 * @see {@link UnbluEmbeddedAppElement.removeEventListener}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent | Custom Event}
 * @see {@link UnbluEmbeddedApi.DeinitializationListener} for the corresponding javascript event on the initialized {@link UnbluEmbeddedApi} instance
 * @noInheritDoc
 */
export declare interface DeinitializationStartedEvent extends CustomEvent<null> {
}

/**
 * Event emitted when the de-initialization of the API has finished.
 *
 * It usually makes sense to use this event if a re-initialization is required after de-init.
 *
 * @see {@link UnbluEmbeddedAppElement.DEINITIALIZED}
 * @see {@link UnbluEmbeddedAppElement.addEventListener}
 * @see {@link UnbluEmbeddedAppElement.removeEventListener}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent | Custom Event}
 * @see {@link UnbluEmbeddedApi.DeinitializationListener} for the corresponding javascript event on the initialized {@link UnbluEmbeddedApi} instance
 * @noInheritDoc
 */
export declare interface DeinitializationFinishedEvent extends CustomEvent<null> {
}

/**
 * Event emitted every time the active conversation changes.
 *
 * @see {@link UnbluEmbeddedAppElement.ACTIVE_CONVERSATION_CHANGE}
 * @see {@link UnbluEmbeddedAppElement.addEventListener}
 * @see {@link UnbluEmbeddedAppElement.removeEventListener}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent | Custom Event}
 * @see {@link UnbluEmbeddedApi.ActiveConversationChangeListener} for the corresponding javascript event on the initialized {@link UnbluEmbeddedApi} instance
 * @noInheritDoc
 */
export declare interface ActiveConversationChangeEvent extends CustomEvent<string | null> {
    /**
     * The id of the new active conversation or null if no conversation is active
     */
    readonly detail: string | null
}

/**
 * Event emitted every time the embedded app switches to fullscreen mode or back.
 *
 * @see {@link UnbluEmbeddedAppElement.FULLSCREEN_CHANGE}
 * @see {@link UnbluEmbeddedAppElement.addEventListener}
 * @see {@link UnbluEmbeddedAppElement.removeEventListener}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent | Custom Event}
 * @see {@link UnbluEmbeddedApi.FullscreenChangeListener} for the corresponding javascript event on the initialized {@link UnbluEmbeddedApi} instance
 * @noInheritDoc
 */
export declare interface FullscreenChangeEvent extends CustomEvent<boolean> {
    /**
     * True if in fullscreen mode, false otherwise
     */
    readonly detail: boolean
}

/**
 * Event emitted every time the view mode changes.
 *
 * @see {@link UnbluEmbeddedAppElement.VIEW_MODE_CHANGE}
 * @see {@link UnbluEmbeddedAppElement.addEventListener}
 * @see {@link UnbluEmbeddedAppElement.removeEventListener}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent | Custom Event}
 * @see {@link UnbluEmbeddedApi.ViewModeChangeListener} for the corresponding javascript event on the initialized {@link UnbluEmbeddedApi} instance
 * @noInheritDoc
 */
export declare interface ViewModeChangeEvent extends CustomEvent<ViewMode> {
    /**
     * The new view mode
     */
    readonly detail: ViewMode
}

/**
 * #### This is a custom element which can be used to integrate Unblu into a webpage at a specific location.
 * By itself this contains only the definition of the custom element and will load further JavaScript from the collaboration server on initialization to provide the full functionality.
 *
 * To use the custom element just place a tag named "unblu-embedded-app" somewhere into the webpage`s `body`.
 *
 * ```html
 * ...
 * <body>
 * ...
 *     <div>
 *         <unblu-embedded-app></unblu-embedded-app>
 *     </div>
 * ...
 * </body>
 * ...
 * ```
 * The size and positioning fully depends on the styles applied to the element by the website it is embedded into.
 * The styling within the unblu embedded app can't be influenced by the website's styles however as a shadow root is used for its content.
 * Unblu provides a large list of configuration properties to nicely integrate the unblu embedded app's visual appearance with the website it is embedded into.
 *
 * The example above will simply add the unblu embedded app into the `DOM` but it won't initialize anything.
 * To initialize the element with Unblu content at least the attribute {@link ATTR_API_KEY} or property {@link UnbluEmbeddedAppElement.apiKey} need to be defined.
 * If {@link UnbluEmbeddedAppElement.autoInit} is set to true (default), it will automatically start the initialization, as soon as the API key is defined either via its attribute {@link ATTR_API_KEY} or property {@link UnbluEmbeddedAppElement.apiKey}.
 * If not a call to {@link UnbluEmbeddedAppElement.initialize} has to be done to trigger the initialization.
 *
 * **Initializing**:
 *
 * Initialization can either be done manually or automatically.
 * If the element is configured to do automatic initialization {@link ATTR_AUTO_INIT} / {@link UnbluEmbeddedAppElement.autoInit} the initialization will be automatically started as soon as at least the API key {@link ATTR_API_KEY} / {@link UnbluEmbeddedAppElement.apiKey} is defined.
 * If you intent to define other attributes/properties relevant for the initialization these must all be set in the same JavaScript execution cycle.
 * If automatic initialization is disabled the initialization must be done manually by calling the {@link UnbluEmbeddedAppElement.initialize} method.
 *
 * There are additional attributes/properties which can be defined to define from where and how the Unblu JavaScript code is loaded from the collaboration server.
 * - {@link ATTR_API_KEY} / {@link UnbluEmbeddedAppElement.apiKey}
 * - {@link ATTR_ENTRY_PATH} / {@link UnbluEmbeddedAppElement.entryPath}
 * - {@link ATTR_SERVER_URL} / {@link UnbluEmbeddedAppElement.serverUrl}
 * - {@link ATTR_NAMED_AREA} / {@link UnbluEmbeddedAppElement.namedArea}
 * - {@link ATTR_ACCESS_TOKEN} / {@link UnbluEmbeddedAppElement.accessToken}
 * - {@link ATTR_LOCALE} / {@link UnbluEmbeddedAppElement.locale}
 * - {@link ATTR_INIT_TIMEOUT} / {@link UnbluEmbeddedAppElement.initTimeout}
 * - {@link ATTR_AUTO_INIT} / {@link UnbluEmbeddedAppElement.autoInit}
 *
 *  The Unblu UI inside the custom element will always fill the whole content of the unblu-embedded-app independent of what size it is given.
 *
 * **Note**: if an Unblu Floating UI is already integrated on the website via the Floating JS API, the embedded app will automatically deinitialize it as there can always only one active instance of Unblu on the page.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements | MDN Custom Element}
 * @noInheritDoc
 */
export class UnbluEmbeddedAppElement extends HTMLElement implements InitializedUnbluElement {
    /**
     * Event emitted whenever the API state changes
     *
     * @see {@link StateChangeEvent} for the event type
     * @see {@link UnbluEmbeddedAppElement.addEventListener} for listener registration
     * @see {@link UnbluEmbeddedAppElement.removeEventListener} to remove a listener
     * @event stateChange
     */
    public static readonly STATE_CHANGE: 'stateChange' = 'stateChange'

    /**
     * Event emitted as soon as the API is initialized.
     *
     * It usually makes sense to use this event to wait for APIs on this element to be ready. E.g. the {@link UnbluEmbeddedAppElement.personInfo} or {@link UnbluEmbeddedAppElement.agentAvailable}.
     * You will find a note in the documentation of the properties when they are only available after initialization.
     *
     * @see {@link ReadyEvent} for the event type
     * @see {@link UnbluEmbeddedAppElement.addEventListener} for listener registration
     * @see {@link UnbluEmbeddedAppElement.removeEventListener} to remove a listener
     * @event ready
     */
    public static readonly READY: 'ready' = 'ready'


    /**
     * Event emitted if the API initialization fails.
     *
     * It usually makes sense to use this event if there is some general action that has to be triggered when the API initialization fails.
     *
     * @see {@link ErrorEvent} for the event type
     * @see {@link UnbluEmbeddedAppElement.addEventListener} for listener registration
     * @see {@link UnbluEmbeddedAppElement.removeEventListener} to remove a listener
     * @event error
     */
    public static readonly ERROR: 'error' = 'error'

    /**
     * Event emitted when the de-initialization of the API is started..
     *
     * It usually makes sense to use this event to clean up resources and/or unregistering of listeners so they don't try to use the API again until it is initialized again.
     *
     * @see {@link DeinitializationStartedEvent} for the event type
     * @see {@link UnbluEmbeddedAppElement.addEventListener} for listener registration
     * @see {@link UnbluEmbeddedAppElement.removeEventListener} to remove a listener
     * @see {@link UnbluEmbeddedApi.DEINITIALIZING} for the event on the initialized {@link UnbluEmbeddedApi} instance
     * @event deinitializing
     */
    public static readonly DEINITIALIZING: typeof UnbluEmbeddedApi.DEINITIALIZING = 'deinitializing'

    /**
     * Event emitted when the de-initialization of the API has finished.
     *
     * It usually makes sense to use this event if a re-initialization is required after de-init.
     *
     * @see {@link DeinitializationFinishedEvent} for the event type
     * @see {@link UnbluEmbeddedAppElement.addEventListener} for listener registration
     * @see {@link UnbluEmbeddedAppElement.removeEventListener} to remove a listener
     * @see {@link UnbluEmbeddedApi.DEINITIALIZED} for the event on the initialized {@link UnbluEmbeddedApi} instance
     * @event deinitialized
     */
    public static readonly DEINITIALIZED: typeof UnbluEmbeddedApi.DEINITIALIZED = 'deinitialized'

    /**
     * Event emitted every time one of the conversations accessible to the current user changes or one is added or removed.
     *
     * @see {@link ActiveConversationChangeEvent} for the event type
     * @see {@link UnbluEmbeddedAppElement.addEventListener} for listener registration
     * @see {@link UnbluEmbeddedAppElement.removeEventListener} to remove a listener
     * @see {@link UnbluEmbeddedApi.ACTIVE_CONVERSATION_CHANGE} for the event on the initialized {@link UnbluEmbeddedApi} instance
     * @event activeConversationChange
     */
    public static readonly ACTIVE_CONVERSATION_CHANGE: typeof UnbluEmbeddedApi.ACTIVE_CONVERSATION_CHANGE = 'activeConversationChange'

    /**
     * Event emitted every time the embedded app switches to fullscreen mode or back.
     *
     * @see {@link UnbluEmbeddedAppElement.FULLSCREEN_CHANGE}
     * @see {@link UnbluEmbeddedAppElement.addEventListener}
     * @see {@link UnbluEmbeddedAppElement.removeEventListener}
     * @see {@link UnbluEmbeddedApi.FULLSCREEN_CHANGE} for the event on the initialized {@link UnbluEmbeddedApi} instance
     * @event fullscreenChange
     */
    public static readonly FULLSCREEN_CHANGE: typeof UnbluEmbeddedApi.FULLSCREEN_CHANGE = 'fullscreenChange'

    /**
     * Event emitted every time the view mode changes.
     *
     * @see {@link UnbluEmbeddedAppElement.VIEW_MODE_CHANGE}
     * @see {@link UnbluEmbeddedAppElement.addEventListener}
     * @see {@link UnbluEmbeddedAppElement.removeEventListener}
     * @see {@link UnbluEmbeddedApi.VIEW_MODE_CHANGE} for the event on the initialized {@link UnbluEmbeddedApi} instance
     * @event viewModeChange
     */
    public static readonly VIEW_MODE_CHANGE: typeof UnbluEmbeddedApi.VIEW_MODE_CHANGE = 'viewModeChange'

    /**
     * The URL to the collaboration server.
     *
     * If not specified it is assumed that the server is reachable on the domain of the current webpage at the defined {@link UnbluEmbeddedAppElement.entryPath}
     *
     * **Note**: Changes to this attribute trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.ATTR_AUTO_INIT} is true.
     *
     * @see {@link UnbluEmbeddedAppElement.serverUrl} to set or get this via a property
     */
    public static readonly ATTR_SERVER_URL: 'server-url' = 'server-url'

    /**
     * The path pointing to the root of the Unblu collaboration server.
     *
     * @default "/unblu".
     *
     * **Note**: Changes to this attribute trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.ATTR_AUTO_INIT} is true.
     *
     * @see {@link UnbluEmbeddedAppElement.entryPath} to set or get this via a property
     */
    public static readonly ATTR_ENTRY_PATH: 'entry-path' = 'entry-path'

    /**
     * The API Key to connect this app with a specific Unblu account
     * This is the minimum configuration that has to be done to trigger the initialization of Unblu.
     *
     * **Note**: Changes to this attribute trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.ATTR_AUTO_INIT} is true.
     *
     * @see {@link UnbluEmbeddedAppElement.apiKey} to set or get this via a property
     */
    public static readonly ATTR_API_KEY: 'api-key' = 'api-key'

    /**
     * The locale (language) used for all Unblu UIs.
     * If not specified this is derived from the webpage or the browser.
     *
     * **Note**: Changes to this attribute trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.ATTR_AUTO_INIT} is true.
     *
     * @see [https://www.unblu.com/en/docs/4.3/articles/installation-and-operation/selecting-the-language.html | Setting the Language / Locale] for more information on the detection.
     * @see {@link UnbluEmbeddedAppElement.locale} to set or get this via a property
     */
    public static readonly ATTR_LOCALE: 'locale' = 'locale'

    /**
     * The named area for which the configuration should be loaded and with which new queue requests will be labeled.
     * If not specified this can be derived from the corresponding meta tag on the webpage or the domain.
     *
     * **Note**: Changes to this attribute trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.ATTR_AUTO_INIT} is true.
     * If this is not desired it can be prevented by changing the named area via a meta tag, however in this case this will only effect the queue routing and not the configuration.
     * Alternatively you can disable the automatic initialization.
     *
     * @see {@link https://www.unblu.com/en/docs/latest/knowledge-base/named-areas.html | Named Area} for further details.
     * @see {@link UnbluEmbeddedAppElement.namedArea} to set or get this via a property
     */
    public static readonly ATTR_NAMED_AREA: 'named-area' = 'named-area'

    /**
     * The access token used to log the user in before initializing Unblu.
     *
     * **Note**: Changes to this attribute trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.ATTR_AUTO_INIT} is true.
     *
     * @see {@link UnbluEmbeddedAppElement.accessToken} to set or get this via a property
     */
    public static readonly ATTR_ACCESS_TOKEN: 'access-token' = 'access-token'

    /**
     * Opens the conversation with the specified ID.
     * If Unblu is not initialized yet, it will wait for the initialization and then automatically open the specified conversation.
     * If Unblu is already initialized, the conversation will be opened directly.
     *
     * If the conversation doesn't exist or the user doesn't have the right to access it, a dialog will be displayed with the respective error message.
     *
     * @see {@link UnbluEmbeddedAppElement.openConversation} to set this value and also retrieve a promise to know, when the conversation was opened.
     * @see {@link UnbluEmbeddedAppElement.conversationId} to read the value as property. This will always represent the currently opened conversation.
     */
    public static readonly ATTR_CONVERSATION_ID: 'conversation-id' = 'conversation-id'

    /**
     * Sets the view mode of the embedded app element.
     *
     * Depending on the view mode the UI is displayed the following way:
     * - {@link ViewMode.SPLIT}: The UI will be divided into two parts.
     * The list of conversations is displayed on the left and the active conversation on the right side.
     *
     * - {@link ViewMode.SINGLE}: Both the conversation list and the active conversation will be displayed in the same view using UI navigation to switch between them.
     *
     * - {@link ViewMode.AUTO}: The UI will automatically switch back and forth between the two other modes depending on the available width.
     * The trigger point when to switch between the two modes can be configured on the Unblu collaboration server.
     *
     * This value can be set even if the API is not initialized yet.
     * The value will then be applied on initialization.
     *
     * @see {@link UnbluEmbeddedAppElement.viewMode} to set or get this via a property
     * @default {@link ViewMode.AUTO}
     */
    public static readonly ATTR_VIEW_MODE: 'view-mode' = 'view-mode'

    /**
     * When this attribute is set to true, the embedded app will ignore the position and size set to its element and will used fixed positioning to display itself to cover the whole website.
     *
     * This is useful when the user is currently in a call.
     *
     * This can be set even if the API is not initialized yet.
     * The value will then be applied on initialization.
     *
     * **Note**: The user itself can also toggle this inside the UI.
     *
     * @see {@link UnbluEmbeddedAppElement.fullscreen} to set or get this via a property
     * @default false
     */
    public static readonly ATTR_FULLSCREEN: 'fullscreen' = 'fullscreen'

    /**
     * The timeout im milliseconds that should be waited for the Unblu integration to load.
     *
     * @default 30'000 (30 seconds).
     *
     * @see {@link UnbluEmbeddedAppElement.initTimeout} to set or get this via a property
     */
    public static readonly ATTR_INIT_TIMEOUT: 'init-timeout' = 'init-timeout'

    /**
     * Defines if Unblu automatically tries to (re-)initialize as soon as an attribute or property changes that triggers (re-)initialization.
     *
     * To trigger a initialization at least the attribute {@link ATTR_API_KEY} or property {@link UnbluEmbeddedAppElement.apiKey}.
     *
     * @default true.
     *
     * @see {@link UnbluEmbeddedAppElement.autoInit} to set or get this via a property
     */
    public static readonly ATTR_AUTO_INIT: 'auto-init' = 'auto-init'

    //initial attributes and properties
    private _serverUrl: string
    private _entryPath: string
    private _apiKey: string
    private _namedArea: string
    private _accessToken: string
    private _locale: string
    private _conversationId: string
    private _viewMode: ViewMode
    private _fullscreen: boolean
    private _initTimeout: number
    private _autoInit: boolean = true

    //internal
    private _configuration: Configuration;
    private _connected: boolean
    private _reinitTrigger: number = -1
    private _initializedApi: UnbluEmbeddedApi
    private _initialization: Promise<UnbluEmbeddedApi>
    private _ongoingDeinitialization: Promise<void>

    //read only properties
    private _state: ApiState = ApiState.INITIAL

    /**
     * Registers for the {@link UnbluEmbeddedAppElement.STATE_CHANGE} event
     * @param type
     * @param listener
     * @param options
     */
    addEventListener(type: typeof UnbluEmbeddedAppElement.STATE_CHANGE, listener: (event: StateChangeEvent) => void, options?: boolean | AddEventListenerOptions): void
    /**
     * Registers for the {@link UnbluEmbeddedAppElement.READY} event
     * @param type
     * @param listener
     * @param options
     */
    addEventListener(type: typeof UnbluEmbeddedAppElement.READY, listener: (event: ReadyEvent) => void, options?: boolean | AddEventListenerOptions): void
    /**
     * Registers for the {@link UnbluEmbeddedAppElement.ERROR} event
     * @param type
     * @param listener
     * @param options
     */
    addEventListener(type: typeof UnbluEmbeddedAppElement.ERROR, listener: (event: ErrorEvent) => void, options?: boolean | AddEventListenerOptions): void
    /**
     * Registers for the {@link UnbluEmbeddedAppElement.DEINITIALIZING} event
     * @param type
     * @param listener
     * @param options
     */
    addEventListener(type: typeof UnbluEmbeddedAppElement.DEINITIALIZING, listener: (event: DeinitializationStartedEvent) => void, options?: boolean | AddEventListenerOptions): void
    /**
     * Registers for the {@link UnbluEmbeddedAppElement.DEINITIALIZED} event
     * @param type
     * @param listener
     * @param options
     */
    addEventListener(type: typeof UnbluEmbeddedAppElement.DEINITIALIZED, listener: (event: DeinitializationFinishedEvent) => void, options?: boolean | AddEventListenerOptions): void
    /**
     * Registers for the {@link UnbluEmbeddedAppElement.ACTIVE_CONVERSATION_CHANGE} event
     * @param type
     * @param listener
     * @param options
     */
    addEventListener(type: typeof UnbluEmbeddedAppElement.ACTIVE_CONVERSATION_CHANGE, listener: (event: ActiveConversationChangeEvent) => void, options?: boolean | AddEventListenerOptions): void
    /**
     * Registers for the {@link UnbluEmbeddedAppElement.FULLSCREEN_CHANGE} event
     * @param type
     * @param listener
     * @param options
     */
    addEventListener(type: typeof UnbluEmbeddedAppElement.FULLSCREEN_CHANGE, listener: (event: FullscreenChangeEvent) => void, options?: boolean | AddEventListenerOptions): void
    /**
     * Registers for the {@link UnbluEmbeddedAppElement.VIEW_MODE_CHANGE} event
     * @param type
     * @param listener
     * @param options
     */
    addEventListener(type: typeof UnbluEmbeddedAppElement.VIEW_MODE_CHANGE, listener: (event: ViewModeChangeEvent) => void, options?: boolean | AddEventListenerOptions): void
    addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
        super.addEventListener(type, listener, options)
    }

    /**
     * Removes a listener registration for the {@link UnbluEmbeddedAppElement.STATE_CHANGE} event
     * @param type
     * @param listener
     */
    removeEventListener(type: typeof UnbluEmbeddedAppElement.STATE_CHANGE, listener: (event: StateChangeEvent) => void): void

    /**
     * Removes a listener registration for the {@link UnbluEmbeddedAppElement.READY} event
     * @param type
     * @param listener
     */
    removeEventListener(type: typeof UnbluEmbeddedAppElement.READY, listener: (event: ReadyEvent) => void): void

    /**
     * Removes a listener registration for the {@link UnbluEmbeddedAppElement.ERROR} event
     * @param type
     * @param listener
     */
    removeEventListener(type: typeof UnbluEmbeddedAppElement.ERROR, listener: (event: ErrorEvent) => void): void

    /**
     * Removes a listener registration for the {@link UnbluEmbeddedAppElement.DEINITIALIZING} event
     * @param type
     * @param listener
     */
    removeEventListener(type: typeof UnbluEmbeddedAppElement.DEINITIALIZING, listener: (event: DeinitializationStartedEvent) => void): void

    /**
     * Removes a listener registration for the {@link UnbluEmbeddedAppElement.DEINITIALIZED} event
     * @param type
     * @param listener
     */
    removeEventListener(type: typeof UnbluEmbeddedAppElement.DEINITIALIZED, listener: (event: DeinitializationFinishedEvent) => void): void

    /**
     * Removes a listener registration for the {@link UnbluEmbeddedAppElement.ACTIVE_CONVERSATION_CHANGE} event
     * @param type
     * @param listener
     */
    removeEventListener(type: typeof UnbluEmbeddedAppElement.ACTIVE_CONVERSATION_CHANGE, listener: (event: ActiveConversationChangeEvent) => void): void

    /**
     * Removes a listener registration for the {@link UnbluEmbeddedAppElement.FULLSCREEN_CHANGE} event
     * @param type
     * @param listener
     */
    removeEventListener(type: typeof UnbluEmbeddedAppElement.FULLSCREEN_CHANGE, listener: (event: FullscreenChangeEvent) => void): void

    /**
     * Removes a listener registration for the {@link UnbluEmbeddedAppElement.VIEW_MODE_CHANGE} event
     * @param type
     * @param listener
     */
    removeEventListener(type: typeof UnbluEmbeddedAppElement.VIEW_MODE_CHANGE, listener: (event: ViewModeChangeEvent) => void): void
    removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
        super.removeEventListener(type, listener, options)
    }

    // noinspection JSUnusedGlobalSymbols
    protected static get observedAttributes(): string[] {
        return [this.ATTR_SERVER_URL, this.ATTR_ENTRY_PATH, this.ATTR_API_KEY, this.ATTR_LOCALE, this.ATTR_NAMED_AREA, this.ATTR_ACCESS_TOKEN, this.ATTR_CONVERSATION_ID, this.ATTR_VIEW_MODE, this.ATTR_FULLSCREEN, this.ATTR_INIT_TIMEOUT, this.ATTR_AUTO_INIT]
    }

    // ------- attribute and properties handling -------

    // noinspection JSUnusedGlobalSymbols
    protected attributeChangedCallback(attrName: string, oldVal: string | null, newVal: string | null) {
        console.debug("attributeChangedCallback", attrName, newVal, "old:", oldVal)
        switch (attrName) {
            case UnbluEmbeddedAppElement.ATTR_SERVER_URL:
                this.serverUrl = newVal
                break
            case UnbluEmbeddedAppElement.ATTR_ENTRY_PATH:
                this.entryPath = newVal
                break
            case UnbluEmbeddedAppElement.ATTR_API_KEY:
                this.apiKey = newVal
                break
            case UnbluEmbeddedAppElement.ATTR_LOCALE:
                this.locale = newVal
                break
            case UnbluEmbeddedAppElement.ATTR_NAMED_AREA:
                this.namedArea = newVal
                break
            case UnbluEmbeddedAppElement.ATTR_ACCESS_TOKEN:
                this.accessToken = newVal
                break;
            case UnbluEmbeddedAppElement.ATTR_CONVERSATION_ID:
                this.conversationId = newVal
                break
            case UnbluEmbeddedAppElement.ATTR_VIEW_MODE:
                this.viewMode = ViewMode[newVal as keyof typeof ViewMode]
                break
            case  UnbluEmbeddedAppElement.ATTR_FULLSCREEN:
                this.fullscreen = newVal === 'true'
                break
            case  UnbluEmbeddedAppElement.ATTR_INIT_TIMEOUT:
                this.initTimeout = Number.parseInt(newVal) || 30000
                break
            case UnbluEmbeddedAppElement.ATTR_AUTO_INIT:
                this.autoInit = newVal === 'true'
                break
            default:
                throw 'Unknown attribute changed! attrName: ' + attrName + ' oldVal: ' + oldVal + ' newVal: ' + newVal
        }
    }

    /**
     * The URL to the collaboration server.
     *
     * If not specified it is assumed that the server is reachable on the same domain of the current webpage at the defined {@link UnbluEmbeddedAppElement.entryPath}
     *
     * **Note**: Changes to this property trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.autoInit} is true.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_SERVER_URL} to set this as an attribute
     * @param serverUrl
     */
    public set serverUrl(serverUrl: string) {
        serverUrl = UnbluUtil.sanitizeParameter(serverUrl)
        if(this._serverUrl === serverUrl) {
            return;
        }
        this._serverUrl = serverUrl
        this.autoReinitIfEnabled()
    }

    public get serverUrl(): string {
        return this._serverUrl
    }

    /**
     * The entry path on the collaboration server.
     *
     * @default "/unblu".
     *
     * **Note**: Changes to this property trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.autoInit} is true.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_ENTRY_PATH} to set this as an attribute
     * @param entryPath
     */
    public set entryPath(entryPath: string) {
        entryPath = UnbluUtil.sanitizeParameter(entryPath)
        if(this._entryPath === entryPath) {
            return;
        }
        this._entryPath = entryPath
        this.autoReinitIfEnabled()
    }

    public get entryPath(): string {
        return this._entryPath
    }

    /**
     * The API Key to connect this app with a specific Unblu account.
     * This is the minimum configuration that has to be done to trigger the initialization of Unblu.
     *
     * **Note**: Changes to this property trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.autoInit} is true.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_API_KEY} to set this as an attribute
     * @param apiKey
     */
    public set apiKey(apiKey: string) {
        apiKey = UnbluUtil.sanitizeParameter(apiKey)
        if(this._apiKey === apiKey) {
            return;
        }
        this._apiKey = apiKey
        this.autoReinitIfEnabled()
    }

    public get apiKey(): string {
        return this._apiKey
    }

    /**
     * The locale (language) used for all Unblu UIs.
     * If not specified this is derived from the webpage or the browser.
     *
     * **Note**: Changes to this property trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.autoInit} is true.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_LOCALE} to set this as an attribute
     * @param locale
     */
    public set locale(locale: string) {
        locale = UnbluUtil.sanitizeParameter(locale)
        if(this._locale === locale) {
            return;
        }
        this._locale = locale
        this.autoReinitIfEnabled()
    }

    public get locale(): string {
        return this._locale
    }

    /**
     * The named area for which the configuration should be loaded and with which new queue requests will be labeled.
     * If not specified this can be derived from meta tags on the webpage.
     *
     * **Note**: Changes to this property trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.autoInit} is true.
     * If this is not desired it can be prevented by changing the named area via a meta tag, however in this case this will only effect the queue routing and not the configuration.
     * Alternatively you can disable the auto init.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_NAMED_AREA} to set this as an attribute
     * @param namedArea
     */
    public set namedArea(namedArea: string) {
        namedArea = UnbluUtil.sanitizeParameter(namedArea)
        if(this._namedArea === namedArea) {
            return;
        }
        this._namedArea = namedArea
        this.autoReinitIfEnabled()
    }

    public get namedArea(): string {
        return this._namedArea
    }

    /**
     * The access token used to log the user in before initializing Unblu.
     *
     * **Note**: Changes to this property trigger a reinitialization of Unblu if {@link UnbluEmbeddedAppElement.autoInit} is true.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_ACCESS_TOKEN} to set this as an attribute
     * @param accessToken
     */
    public set accessToken(accessToken: string) {
        accessToken = UnbluUtil.sanitizeParameter(accessToken)
        if(this._accessToken === accessToken) {
            return;
        }
        this._accessToken = accessToken
        this.autoReinitIfEnabled()
    }

    public get accessToken(): string {
        return this._accessToken
    }

    /**
     * Opens the conversation for the given conversation ID.
     *
     * This can be set already before Unblu is initialized.
     * The conversation will then be opened as soon as Unblu gets initialized.
     *
     * If the conversation doesn't exist or the user doesn't have the right to access it, a dialog will be displayed with the respective error message.
     * In such a case the conversation ID will be reset to `null`.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_CONVERSATION_ID} to set this as an attribute
     * @param conversationId The id of the conversation to open
     */
    public set conversationId(conversationId: string) {
        conversationId = UnbluUtil.sanitizeParameter(conversationId)
        this._conversationId = conversationId
        if (this._initializedApi) {
            // noinspection JSIgnoredPromiseFromCall
            this._initializedApi.openConversation(conversationId)
        }
    }

    public get conversationId(): string {
        return this._conversationId
    }

    /**
     * Sets the view mode of the embedded app element.
     *
     * Depending on the view mode the UI is displayed the following way:
     * - {@link ViewMode.SPLIT}: The UI will be divided into two parts.
     * The list of conversations is displayed on the left and the active conversation on the right side.
     *
     * - {@link ViewMode.SINGLE}: Both the conversation list and the active conversation will be displayed in the same view using UI navigation to switch between them.
     *
     * - {@link ViewMode.AUTO}: The UI will automatically switch back and forth between the two other modes depending on the available width.
     * The trigger point when to switch between the two modes can be configured on the Unblu collaboration server.
     *
     * This value can be set even if the API is not initialized yet.
     * The value will then be applied on initialization.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_VIEW_MODE} to set this as an attribute
     * @see {@link UnbluEmbeddedApi.setViewMode} to set this on the initialized {@link UnbluEmbeddedApi}
     * @param viewMode The new view mode to use
     * @default {@link ViewMode.AUTO}
     */
    public set viewMode(viewMode: ViewMode) {
        if (viewMode == null) {
            viewMode = ViewMode.AUTO
        }
        this._viewMode = viewMode
        if (this._initializedApi) {
            // noinspection JSIgnoredPromiseFromCall
            this._initializedApi.setViewMode(viewMode)
        }
    }

    public get viewMode(): ViewMode {
        if (this._viewMode) {
            return this._viewMode
        }
        return ViewMode.AUTO
    }

    /**
     * When this attribute is set to true, the embedded app element will ignore the position and size set to its element and will used fixed positioning to display itself to cover the whole website.
     *
     * This is useful when the user is currently in a call.
     *
     * This can be set even if the API is not initialized yet.
     * The value will then be applied on initialization.
     *
     * **Note**: The user itself can also toggle this inside the UI.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_FULLSCREEN} to set this as an attribute
     * @see {@link UnbluEmbeddedApi.setFullscreen} to set this on the initialized {@link UnbluEmbeddedApi}
     * @param fullscreen True to enable fullscreen, false otherwise.
     *
     * @default false
     */
    public set fullscreen(fullscreen: boolean) {
        this._fullscreen = fullscreen
        if (this._initializedApi) {
            // noinspection JSIgnoredPromiseFromCall
            this._initializedApi.setFullscreen(fullscreen)
        }
    }

    public get fullscreen(): boolean {
        if (this._fullscreen) {
            return this._fullscreen
        }
        return false
    }

    /**
     * The timeout im milliseconds that should be waited for the Unblu integration to load.
     *
     * @default 30'000 (30 seconds).
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_INIT_TIMEOUT} to set this as an attribute
     * @param initTimeout
     */
    public set initTimeout(initTimeout: number) {
        this._initTimeout = initTimeout
    }

    public get initTimeout(): number {
        return this._initTimeout
    }

    /**
     * Defines if Unblu automatically tries to (re-)initialize as soon as an attribute or property changes that triggers (re-)initialization.
     * Attributes/properties that trigger a (re-)initialization have a note in their documentation.
     * At least the attribute {@link ATTR_API_KEY} or property {@link UnbluEmbeddedAppElement.apiKey} has to be configured to trigger an initialization.
     *
     * If this property gets set from false to true, it will automatically initialize if it is not already initialized.
     * If it is already initialized, it will NOT deinitialize.
     *
     * @default true.
     *
     * @see {@link UnbluEmbeddedAppElement.ATTR_AUTO_INIT} to set this as an attribute
     * @param autoInit
     */
    public set autoInit(autoInit: boolean) {
        if(this._autoInit === autoInit) {
            return;
        }
        this._autoInit = autoInit
        if (autoInit && (this.apiState === ApiState.INITIAL || this.apiState === ApiState.DEINITIALIZING || this.apiState === ApiState.DEINITIALIZED)) {
            this.autoReinitIfEnabled()
        }
    }

    public get autoInit(): boolean {
        return this._autoInit
    }

    /**
     * The current state of the API.
     * @see {@link UnbluEmbeddedAppElement.STATE_CHANGE} to listen for changes or use one of the following for specific states.
     * @see {@link UnbluEmbeddedAppElement.READY} for {@link ApiState.INITIALIZED}
     * @see {@link UnbluEmbeddedAppElement.DEINITIALIZING} for {@link ApiState.DEINITIALIZING}
     * @see {@link UnbluEmbeddedAppElement.DEINITIALIZED} for {@link ApiState.DEINITIALIZED}
     * @see {@link UnbluEmbeddedAppElement.ERROR} for {@link ApiState.ERROR}
     *
     * @readonly
     */
    public get apiState(): ApiState {
        return this._state
    }

    /**
     * Initializes the app element to connect to the configured collaboration server and load the UI.
     *
     * If the API is already initializing or fully initialized, additional calls to this function will return the same instance of {@link UnbluEmbeddedApi}.
     *
     * This needs to be called if {@link UnbluEmbeddedAppElement.autoInit} is set to false.
     *
     * @return UnbluEmbeddedApi after initialization was successful
     *
     * @see {@link UnbluEmbeddedAppElement.deinitialize}
     * @see {@link UnbluEmbeddedApi.deinitialize}
     */
    public async initialize(): Promise<UnbluEmbeddedApi> {
        return this.internalInitialize(true)
    }

    // noinspection JSUnusedGlobalSymbols
    protected async connectedCallback() {
        console.debug("connectedCallback")
        this._connected = true
        if (this._autoInit) {
            await this.internalInitialize(false)
        }
    }

    // noinspection JSUnusedGlobalSymbols
    protected adoptedCallback() {
        console.debug("adoptedCallback")
    }

    // noinspection JSUnusedGlobalSymbols
    protected async disconnectedCallback() {
        console.debug("disconnectedCallback")
        this._connected = false
        await this.deinitialize()
    }

    private isConfigured(): boolean {
        return this.apiKey != null && this.apiKey.length > 0
    }

    private async internalInitialize(explicit: boolean): Promise<UnbluEmbeddedApi> {
        console.debug("initialize")
        if (this._initialization) {
            return this._initialization
        }
        if (!this._connected) {
            console.log("Initialize request is not executed as the element is not attached to the DOM.")
            if (explicit) {
                //throw an error as it was explicitly triggered from the outside
                throw new UnbluApiError(UnbluErrorType.CONFIGURATION_MISSING, 'Error initializing Unblu as the element is not attached to the DOM!')
            }
            return
        }
        if (!this.isConfigured()) {
            console.log("Initialize request is not executed as the element is not configured.")
            if (explicit) {
                //throw an error as it was explicitly triggered from the outside
                throw new UnbluApiError(UnbluErrorType.CONFIGURATION_MISSING, 'Error initializing Unblu as configuration is missing!')
            }
            return
        }
        if (!(this.apiState === ApiState.INITIAL || this.apiState === ApiState.DEINITIALIZING || this.apiState === ApiState.DEINITIALIZED)) {
            console.log("Initialize request is not executed as apiState is wrong. ApiState: ", this.apiState)
            if (explicit) {
                //throw an error as it was explicitly triggered from the outside
                throw new UnbluApiError(UnbluErrorType.ILLEGAL_STATE, 'Error initializing Unblu as it is in the wrong state! ApiState: ' + this.apiState + '. It may needs to be properly deinitialized first')
            }
            return
        }
        return this._initialization = new Promise<UnbluEmbeddedApi>(async (resolve, reject) => {
            if (this.apiState == ApiState.DEINITIALIZING) {
                //wait for the deinitialization to finish
                try {
                    await this.deinitialize()
                } catch (e) {
                    console.debug("Error while waiting for deinit during initialization request", e)
                }
            }
            try {
                await this.internalStartUp()
                this.setApiState(ApiState.INITIALIZED)
                this.dispatchEvent(new CustomEvent(UnbluEmbeddedAppElement.READY, {detail: this._initializedApi}))
                resolve(this._initializedApi)
            } catch (e) {
                this.handleStartupError(e)
                reject(e)
            }
        })
    }

    private async internalStartUp() {
        this.setApiState(ApiState.INITIALIZING)
        this._configuration = this.createConfigFromProperties()
        await UnbluUtil.deinitializeFloatingIfNeeded()
        await UnbluUtil.deinitializeEmbeddedIfNeeded(this)

        if (!UnbluUtil.isUnbluLoaded(IntegrationType.embedded)) {
            if (this._configuration.namedArea) {
                UnbluUtil.setNamedArea(this._configuration.namedArea)
            }
            if (this._configuration.locale) {
                UnbluUtil.setLocale(this._configuration.locale)
            }
            if(this.accessToken) {
                await UnbluUtil.loginWithSecureToken(this._configuration.serverUrl || '', this._configuration.apiKey, this._configuration.entryPath || '/unblu', this._configuration.accessToken)
            }
            await UnbluEmbeddedAppElement.injectUnblu(this._configuration)
        }
        const apiBridge = new ApiBridge(UnbluUtil.getUnbluObject(), 'internal-embedded')
        await apiBridge.waitUntilLoaded(this._configuration.initTimeout || 30000)

        const internalApi = new InternalEmbeddedApi(apiBridge, this._configuration)
        internalApi.checkCompatibility()

        await internalApi.meta.setUnbluEmbeddedAppElement(this)

        // save reference in global scope to later detect it on integration type change
        UnbluUtil.getUnbluObject()['embedded-internal-element'] = this;

        // Check internalApi waitUntilInitialized
        await internalApi.meta.waitUntilInitialized()

        this._initializedApi = new UnbluEmbeddedApi(internalApi)
        //conversation id and fullscreen will be overwritten by the populatePropertiesAndEvents as it waits for the current value
        const conversationIdToOpen = this._conversationId
        const fullscreenToSet = this._fullscreen
        const viewModeToSet = this._viewMode
        await this.populatePropertiesAndEvents()

        if (conversationIdToOpen) {
            try {
                await this._initializedApi.openConversation(conversationIdToOpen)
            } catch (e) {
                console.log("Could not open conversation from attr/property", e)
            }
        }
        //only set the values if they were really defined
        if (fullscreenToSet !== undefined) {
            this.fullscreen = fullscreenToSet
        }
        if (viewModeToSet !== undefined) {
            this.viewMode = viewModeToSet
        }
    }

    private createConfigFromProperties(): Configuration {
        return {
            apiKey: this.apiKey,
            serverUrl: this.serverUrl,
            entryPath: this.entryPath,
            locale: this.locale,
            namedArea: this.namedArea,
            initTimeout: this.initTimeout,
            accessToken: this.accessToken
        }
    }

    private static async injectUnblu(config: Configuration) {
        const serverUrl = config.serverUrl || ''
        const apiKey = config.apiKey
        const unbluPath = config.entryPath || '/unblu'
        let unbluUrl = `${serverUrl}${unbluPath}/embedded-visitor.js?x-unblu-apikey=${apiKey}`
        try {
            await UnbluUtil.loadScript(unbluUrl, config.initTimeout)
        } catch (e) {
            throw new UnbluApiError(UnbluErrorType.ERROR_LOADING_UNBLU, 'Error loading unblu snippet: ' + e + ' check the configuration.')
        }
    }

    private handleStartupError(error: UnbluApiError) {
        this._initialization = null
        this._initializedApi = null
        this.setApiState(ApiState.ERROR)
        this.dispatchEvent(new CustomEvent(UnbluEmbeddedAppElement.ERROR, {detail: error}))
        //this.eventEmitter.emit(ApiState.ERROR, error)
        if (UnbluErrorType.UNSUPPORTED_BROWSER != error.type) {
            console.error(error)
        }
    }

    private async populatePropertiesAndEvents() {
        await this._initializedApi.on(UnbluEmbeddedApi.ACTIVE_CONVERSATION_CHANGE, (conversation: Conversation) => {
            const conversationId = conversation ? conversation.getConversationId() : null
            this._conversationId = conversationId
            this.dispatchEvent(new CustomEvent(UnbluEmbeddedAppElement.ACTIVE_CONVERSATION_CHANGE, {detail: conversationId}))
        })
        await this._initializedApi.on(UnbluEmbeddedApi.FULLSCREEN_CHANGE, (fullscreen: boolean) => {
            this._fullscreen = fullscreen
            this.dispatchEvent(new CustomEvent(UnbluEmbeddedAppElement.FULLSCREEN_CHANGE, {detail: fullscreen}))
        })
        await this._initializedApi.on(UnbluEmbeddedApi.VIEW_MODE_CHANGE, (viewMode: ViewMode) => {
            this._viewMode = viewMode
            this.dispatchEvent(new CustomEvent(UnbluEmbeddedAppElement.VIEW_MODE_CHANGE, {detail: viewMode}))
        })
        // noinspection ES6MissingAwait
        this._initializedApi.on(UnbluEmbeddedApi.DEINITIALIZING, () => {
            this._conversationId = null
            this._initializedApi = null
            this._initialization = null
            this.setApiState(ApiState.DEINITIALIZING)
            this.dispatchEvent(new CustomEvent(UnbluEmbeddedAppElement.DEINITIALIZING, {detail: null}))
        })
        // noinspection ES6MissingAwait
        this._initializedApi.on(UnbluEmbeddedApi.DEINITIALIZED, () => {
            this.setApiState(ApiState.DEINITIALIZED)
            this.dispatchEvent(new CustomEvent(UnbluEmbeddedAppElement.DEINITIALIZED, {detail: null}))
        })
    }

    private setApiState(apiState: ApiState) {
        this._state = apiState
        this.dispatchEvent(new CustomEvent(UnbluEmbeddedAppElement.STATE_CHANGE, {detail: apiState}))
    }

    private autoReinitIfEnabled() {
        clearTimeout(this._reinitTrigger)
        if (!this.autoInit || !this._connected) {
            return
        }
        this._reinitTrigger = window.setTimeout(async () => {
            this._reinitTrigger = -1
            await this.deinitialize()
            await this.internalInitialize(false)
        }, 0)
    }

    /**
     * Deinitializes the app element and destroys all of its containing UI.
     * By changing one of the attributes that trigger a reinitialization (e.g. {@link UnbluEmbeddedAppElement.apiKey}) the app element will be automatically initialized again (if the value is really changed).
     *
     * **Note**: Calling this method will not remove the element itself out of the DOM.
     */
    public deinitialize(): Promise<void> {
        console.debug("deinitialize")
        if (this._ongoingDeinitialization) {
            return this._ongoingDeinitialization
        }
        if (this.apiState === ApiState.INITIAL || this.apiState === ApiState.DEINITIALIZED) {
            //nothing to do in this case
            return Promise.resolve()
        }
        return this._ongoingDeinitialization = new Promise(async (resolve, reject) => {
            if (this.apiState === ApiState.INITIALIZING) {
                //wait for the initialization to be done
                try {
                    await this.initialize()
                } catch (e) {
                    console.debug("Error while waiting for init during deinitialization request", e)
                }
            }
            //deinitialize
            try {
                if (this._initializedApi) {
                    if (this._configuration.namedArea) {
                        UnbluUtil.removeNamedArea()
                    }
                    await this._initializedApi.deinitialize()
                }
            } catch (e) {
                this._initializedApi = null
                this._initialization = null
                reject(e)
                return
            } finally {
                this._ongoingDeinitialization = null
                UnbluUtil.getUnbluObject()['embedded-internal-element'] = null
            }
            resolve()
        })
    }
}

window.customElements.define('unblu-embedded-app', UnbluEmbeddedAppElement)