import {InternalModule} from './module'
import {ApiBridge} from '../api-bridge'
import {CallState} from '../../model/call-state'
import {ConversationState} from '../../model/conversation-state'
import {ConnectionState} from '../../model/connection-state'


export interface ConversationCallState {
    conversationId: string
    callState: CallState
}

export interface ConversationConnectionState {
    conversationId: string
    connectionState: ConnectionState
}

export interface ConversationWithState {
    conversationId: string
    conversationState: ConversationState
}

export type ConversationEventType =
    'connectionStateChange' |
    'conversationStateChange' |
    'end' |
    'close' |
    'personChange' |
    'callStateChange' |
    'invitationsChange' |
    'customActionInvocation'

const enum ConversationFunction {
    getConnectionState = 'getConnectionState',
    getConversationState = 'getConversationState',
    getCallState = 'getCallState',
    startAudioCall = 'startAudioCall',
    startVideoCall = 'startVideoCall',
    endConversation = 'endConversation',
    leaveConversation = 'leaveConversation',
    closeConversation = 'closeConversation',
    setVisitorData = 'setVisitorData',
    getVisitorData = 'getVisitorData'
}

export class ConversationModule extends InternalModule<ConversationFunction, ConversationEventType> {
    constructor(bridge: ApiBridge) {
        super(bridge, 'conversation')
    }

    public getConnectionState(conversationId: string): Promise<ConnectionState> {
        return this.callApiFunction(ConversationFunction.getConnectionState, [conversationId])
    }

    public getConversationState(conversationId: string): Promise<ConversationState> {
        return this.callApiFunction(ConversationFunction.getConversationState, [conversationId])
    }

    public getCallState(conversationId: string): Promise<CallState> {
        return this.callApiFunction(ConversationFunction.getCallState, [conversationId])
    }

    public startAudioCall(conversationId: string): Promise<void> {
        return this.callApiFunction(ConversationFunction.startAudioCall, [conversationId])
    }

    public startVideoCall(conversationId: string): Promise<void> {
        return this.callApiFunction(ConversationFunction.startVideoCall, [conversationId])
    }

    public endConversation(conversationId: string): Promise<void> {
        return this.callApiFunction(ConversationFunction.endConversation, [conversationId])
    }

    public leaveConversation(conversationId: string): Promise<void> {
        return this.callApiFunction(ConversationFunction.leaveConversation, [conversationId])
    }

    public closeConversation(conversationId: string): Promise<void> {
        return this.callApiFunction(ConversationFunction.closeConversation, [conversationId])
    }

    public async setVisitorData(visitorData: String) : Promise<void> {
        return this.callApiFunction(ConversationFunction.setVisitorData, [visitorData]);
    }

    public async getVisitorData() : Promise<String> {
        return this.callApiFunction(ConversationFunction.getVisitorData, null);
    }
}